// talenzz colors
$zz_dark: #161511
$zz_light: #f2f2f2
$zz_lachs: #f4b5a8
$zz_himmel: #affcf4
$zz_blau: #2f29b2
$zz_blue: #355be0
$zz_gelb: #fcf8b7


// overwrites:
$color-contrast-dark: $zz_dark
$color-contrast-light: $zz-light

$body-color: $zz_dark

$light: $zz_light
$dark: $zz_dark

$navbar-height: 66px

// talenzz typography
$font-family-sans-serif: 'Epilogue', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif
$headings-font-weight: 700

$input-font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif
$btn-font-family:  'Bebas Neue', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif

// talenzz buttons
$btn-border-radius: 999px
$btn-border-radius-sm: 999px
$btn-border-radius-lg: 999px

$btn-padding-x: 1.125rem
$btn-padding-x-sm: 0.75rem
$btn-padding-x-lg: 1.5rem
