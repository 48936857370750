.dashboardheader
  &__box
    background-color: $quaternary
    padding: 1.5rem
    border-radius: var(--bs-border-radius-xxl)
    margin-bottom: 1rem
    &__label
      color:  var(--bs-gray-800)
      font-weight: 200
    &__value
      font-weight: bold