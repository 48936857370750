// Index Styles
  
//mixins
@import "styles/mixins/fontface"

// fonts
@include fontFace('Epilogue', '/assets/fonts/Epilogue-Regular')
@include fontFace('Epilogue', '/assets/fonts/Epilogue-Italic', italic)
@include fontFace('Epilogue', '/assets/fonts/Epilogue-Bold', normal, bold)
@include fontFace('Epilogue', '/assets/fonts/Epilogue-Black', normal, 900)
@include fontFace('Epilogue', '/assets/fonts/Epilogue-Light', normal, 300)
@include fontFace('Bebas Neue', '/assets/fonts/BebasNeue-Regular')

// theme
@import "../node_modules/bootstrap/scss/functions"
@import "styles/prebootstrapvariables"
@import "styles/bootstrapvariables"

// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap"

//stylesheets components
@import "components/Artist/ArtistShow/ArtistShow"
@import "components/Campaign/CampaignShow/CampaignShow"
@import "components/Campaign/CampaignProgress/CampaignProgress"
@import "components/Dashboard/DashboardHeader/DashboardHeader"
@import "components/Home/HomeHero/HomeHero"

#appcontent
  margin-top: $navbar-height