.homehero
  &__item
    &__text
      min-height: 78vw
      @include media-breakpoint-up(sm)
        min-height: 45vw
      @include media-breakpoint-up(md)
        min-height: 34vw
      @include media-breakpoint-up(lg)
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
      @include media-breakpoint-up(xl)
        font-size: 200%
        .homehero__item__text__subtitle, .homehero__item__text__headline
          max-width: 66%
      @media (min-width: 1600px)
        font-size: 250%
      &__wrapper
        margin-top: auto
      &__subtitle
        max-width: 70ch
      &__headline
        font-size: calc(1.375rem + 1.5vw)