.campaignshow
  &__hero
    position: relative
    margin-bottom: 3rem
  &__campaigninvest
    display: flex
    justify-content: center
    margin-top: -10%
    left: 0
    right: 0
    @include media-breakpoint-up(lg)
      position: absolute
      bottom: -180px