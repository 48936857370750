// global vars
$tier-base-color: #494949
$tier-progress-color: $success

.campaign-progress
  $component-height: 50px
  $line-height: calc($component-height / 2)
  $break-size: $line-height

  &__container
    display: flex
    flex-direction: column
    position: relative
    width: 100%
    height: $component-height

  &__chart-container
    display: flex
    flex-flow: column
    align-items: center
    flex: auto

  &__line-container
    position: absolute
    display: flex
    align-items: center
    width: 100%
    height: $break-size

  &__line
    align-self: center
    position: absolute
    top: calc($break-size / 2)
    transform: translateY(-50%)
    order: 1
    width: 100%
    height: $line-height
    background-color: rgba($tier-base-color, 0.1)
    border: 1px solid rgba($tier-base-color, 0.4)
    border-radius: 5px

    &--left
      order: 0
      background-color: $tier-progress-color

  &__break-container
    position: absolute
    height: $break-size
    width: 100%

  &__break
    position: absolute
    width: 2px
    height: $break-size
    background-color: $tier-base-color
    transform: translateX(-50%)
    transition: all 0.25s ease-out

  &__label-container
    display: flex
    flex-flow: column nowrap
    align-items: flex-start
    flex: 1 0%

  &__label
    position: relative
    font-size: 0.7rem
    font-weight: 600
    color: rgba($tier-base-color, 0.7)
    text-align: center

    &--colored
      color: $tier-progress-color

  &__tiers
    position: absolute
    transform: translate(-50%, 20%)

  =tier($mark)
    &--#{$mark}
      left: #{$mark + '%'}

  &__tier
    // generate position and color of each tier value
    @for $j from 0 to 101
      +tier($j)
