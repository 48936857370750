.artistshow
  &__header
    &__gradient
      background: rgb(0,0,0)
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)
      color: var(--bs-light)
  &__navigation
    position: sticky
    top: calc($navbar-height - 1px)
    z-index: 1000
    background-color: $white
    .nav-link
      @media (max-width: 400px)
        font-size: 80%